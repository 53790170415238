<template>
  <div class="badges-list">
    <div class="mem-font--badges-list__category-title group-title">
      {{ formattedGroupName }}
    </div>
    <div class="group-items">
      <div
        class="badge"
        v-for="(badge, index) in badges"
        v-bind:key="index"
        :class="badge.earned ? '' : 'not-earned'"
      >
        <img :src="badge.badge_icon_url" alt="badge icon" />
        <span>{{ badge.badge_name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  props: ["groupName", "badges"],
  computed: {
    formattedGroupName() {
      return this.groupName.replace(/_/g, " ");
    },
  },
};
</script>
<style lang="scss" scoped>
.badges-list {
  .group-title {
    padding-bottom: 8px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .group-items {
    display: grid;
    grid-template-columns: repeat(4, 125px);
    gap: 65px 0;
    margin: 30px 0 65px 0;
    justify-content: space-between;
    .badge {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      text-transform: uppercase;
      img {
        height: 75px;
        width: 75px;
        margin-bottom: 20px;
      }
    }
    .not-earned {
      opacity: 0.4;
    }
  }
}
@media screen and (max-width: 900px) {
  .badges-list {
    .group-items {
      grid-template-columns: repeat(auto-fill, 125px);
      gap: 65px 10px;
    }
  }
}
@media screen and (max-width: 499px) {
  .badges-list {
    .group-items {
      gap: 52px 0px;
      margin-top: 50px;
      justify-content: space-around;
    }
  }
}
@media screen and (max-width: 375px) {
  .badges-list {
    .group-items {
      gap: 42px 44px;
      margin-top: 35px;
      justify-content: center;
    }
  }
}
</style>