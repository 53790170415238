<template>
  <div class="trophy-card">
    <img :src="trophy.badge_icon_url" alt="trophy icon" />
    <div class="trophy-description">
      <div class="earned-date">{{ earnedDate }}</div>
      <div>{{ trophy.badge_name }}</div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data: () => ({}),
  props: ["trophy"],
  computed: {
    earnedDate() {
      return moment(this.trophy.date_earned).format("MMM DD, YYYY");
    },
  },
};
</script>
<style lang="scss" scoped>
.trophy-card {
  height: 140px;
  background: #121212;
  padding: 40px 36px;

  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center;
  gap: 30px;
  @media screen and (max-width: $mobile) {
    padding: 40px 36px 40px 18px;
    gap: 20px;
  }
  @media screen and (max-width: 375px) {
    padding: 40px 22px 40px 18px;
  }
  img {
    height: 60px;
    width: 60px;
  }
  .trophy-description {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    .earned-date {
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #9d9d9d;
      margin-bottom: 4px;
      @media screen and (max-width: 375px) {
        margin-bottom: 2px;
      }
    }
  }
}
</style>