<template>
  <div class="mem-view tropaion-view">
    <div class="tropaion-header">
      <div class="mem-title">{{ $t("your_tropaion") }}</div>
      <v-menu transition="slide-y-transition" bottom left>
        <template v-slot:activator="{ on, attrs }">
          <div class="selected-year cursor-pointer" v-bind="attrs" v-on="on">
            <span>{{ selectedYear }}</span>
            <i class="material-icons">keyboard_arrow_down</i>
          </div>
        </template>
        <div class="year-select-menu">
          <template v-for="(year, index) in availableYears">
            <div
              @click="selectedYear = year"
              class="menu-line cursor-pointer"
              :key="index"
            >
              {{ year }}
            </div>
          </template>
        </div>
      </v-menu>
    </div>
    <div class="last-trophy" v-if="lastTrophy">
      <div class="mem-subtitle">{{ $t("last_trophy") }}</div>
      <trophy-card :trophy="lastTrophy" />
    </div>
    <div class="challenges-section" v-if="filteredChallengesList.length !== 0">
      <div class="challenges-header">
        <div class="mem-subtitle">{{ $t("challenges") }}</div>
        <div class="challehges-link">
          <div @click="openChallengesPage" class="mem-show-desktop">
            {{ $t("see_all") }}
          </div>
          <div class="mem-show-mobile">
            <mem-button
              :btnType="'tertiary-dark'"
              @click="openChallengesPage"
              >{{ $t("see_all") }}</mem-button
            >
          </div>
        </div>
      </div>
      <div>
        <div v-for="(challenge, i) in filteredChallengesList" v-bind:key="i">
          <challenge-card :challenge="challenge" v-if="i <= 2" />
        </div>
      </div>
    </div>
    <div class="badges-section">
      <div class="badges-header">
        <div class="title-counter">
          <div class="mem-subtitle">{{ $t("your_trophies") }}</div>
          <div class="badges-counter">
            <span>{{ earnedCount }}</span>
            <span class="total-count">/{{ totalCount }}</span>
          </div>
        </div>
      </div>
      <!-- <div>{{ earnedCount + "/" + totalCount }}</div> -->
      <div v-for="(badges, key) in groupedBadgesList" v-bind:key="key">
        <badges-list :group-name="key" :badges="badges" />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import BadgesList from "./components/BadgesList.vue";
import TrophyCard from "./components/TrophyCard.vue";
import ChallengeCard from "./components/ChallengeCard.vue";

export default {
  data: () => ({
    selectedYear: new Date().getFullYear(),
    availableYears: [2020, 2021, 2022],
  }),
  computed: {
    ...mapGetters(["badgesList", "challengesList"]),
    groupedBadgesList() {
      let list = _.filter(this.badgesList, "badge_category");
      list = _.groupBy(list, (item) => {
        return item.badge_year;
      });
      _.forEach(list, (value, key) => {
        list[key] = _.groupBy(list[key], (item) => {
          return item.badge_category;
        });
      });
      return list[this.selectedYear];
    },
    earnedCount() {
      let earnedList = _.filter(this.badgesList, {
        badge_year: this.selectedYear,
        earned: true,
      });
      return earnedList.length;
    },
    totalCount() {
      let list = _.filter(this.badgesList, { badge_year: this.selectedYear });
      return list.length;
    },
    lastTrophy() {
      let sortedList = _.orderBy(
        _.filter(this.badgesList, "date_earned"),
        ["date_earned"],
        ["desc"]
      );
      return sortedList[0];
    },
    groupedChallengesList() {
      let limitedChallenges = _.filter(this.challengesList, (challenge) => {
        return challenge.start_date || challenge.end_date;
      });

      let endlessChallenges = _.filter(this.challengesList, (challenge) => {
        return !challenge.start_date && !challenge.end_date;
      });

      let selectedYearChallenges = _.filter(limitedChallenges, (challenge) => {
        let startDate = _.toNumber(moment(challenge.start_date).format("YYYY"));
        let endDate = _.toNumber(moment(challenge.end_date).format("YYYY"));

        return startDate === this.selectedYear || endDate === this.selectedYear;
      });

      return [...selectedYearChallenges, ...endlessChallenges];
    },
    filteredChallengesList() {
      let commitedList = _.filter(this.groupedChallengesList, (challenge) => {
        return (
          challenge.committed &&
          (challenge.steps_done || challenge.steps_done === 0) &&
          challenge.steps_needed &&
          challenge.steps_done !== challenge.steps_needed
        );
      });
      let availableList = _.filter(this.groupedChallengesList, (challenge) => {
        return !challenge.committed;
        // return (
        //   (!challenge.committed && !challenge.end_date) ||
        //   (!challenge.committed && moment() <= moment(challenge.end_date))
        // );
      });
      let completedList = _.filter(this.groupedChallengesList, (challenge) => {
        return (
          challenge.steps_done &&
          challenge.steps_needed &&
          challenge.steps_done === challenge.steps_needed
        );
      });
      return [...commitedList, ...availableList, ...completedList];
    },
  },
  components: {
    BadgesList,
    TrophyCard,
    ChallengeCard,
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  methods: {
    ...mapActions(["getBadges", "getChallenges"]),
    openChallengesPage() {
      this.$router.push({
        name: "challenges",
        // query: { year: this.selectedYear },
      });
    },
  },
  async mounted() {
    await this.getBadges();
    await this.getChallenges();
  },
  watch: {
    selectedYear(val) {
      console.log(val);
      console.log(this.groupedChallengesList);
    },
  },
};
</script>
<style lang="scss" scoped>
.year-select-menu {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #000000;
  border: 0.5px solid #4f4f4f;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  padding: 12px;
  .menu-line {
    height: 38px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    padding: 12px 0 12px 0;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.5s;
    text-align: center;
  }
  .menu-line:hover {
    background: rgba(255, 255, 255, 0.08);
    transform: scale(1.03);
  }
}
.tropaion-view {
  max-width: 670px;
  .mem-title,
  .mem-subtitle {
    padding-left: 0;
  }
  .tropaion-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include elementMarginBottom("M");
    @media screen and (max-width: 499px) {
      padding: 0 24px;
    }
    @media screen and (max-width: 499px) {
      padding: 0 20px;
      flex-direction: column;
      align-items: inherit;
    }
    .mem-title {
      @media screen and (max-width: 499px) {
        @include elementMarginBottom("M");
      }
    }
    .selected-year {
      height: 44px;
      border: 1px solid #818181;
      border-radius: 100px;

      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.0025em;
      font-weight: 600;

      padding-left: 23px;
      padding-right: 10px;

      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 499px) {
        // margin-top: 30px;
        justify-content: space-between;
      }

      i {
        margin-left: 10px;
      }
    }
  }
  .last-trophy {
    @include elementMarginBottom("M");
    .mem-subtitle {
      @include elementMarginBottom("S");
      @media screen and (max-width: $mobile) {
        padding-left: 20px;
      }
    }
  }
  .challenges-section {
    @include elementMarginBottom("M");
    .challenges-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      @include elementMarginBottom("S");
      @media screen and (max-width: 499px) {
        padding-right: 20px;
        padding-left: 20px;
      }
      .challehges-link {
        @include cursorPointer;
        button {
          height: 36px;
          padding: 0 32px;
        }
      }
    }
  }
  .badges-section {
    @media screen and (max-width: 499px) {
      padding: 0 24px;
    }
    @media screen and (max-width: 375px) {
      padding: 0 20px;
    }
    .badges-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @include elementMarginBottom("S");
      .title-counter {
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 499px) {
          flex-direction: column;
        }
        .badges-counter {
          padding-top: 5px;
          margin-left: 26px;
          font-size: 18px;
          line-height: 22px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.0025em;
          @media screen and (max-width: 899px) {
            margin-left: 12px;
          }
          @media screen and (max-width: 499px) {
            font-size: 14px;
            line-height: 17px;
            margin-left: 0;
            padding-top: 4px;
          }
          .total-count {
            opacity: 0.4;
          }
        }
      }
    }
  }
}
</style>